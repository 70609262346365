export const Pproducts = [
  {
    id: '1',
    name: 'Provo Portfolio',
    title: '',
    imageUrl:
      './Thumbnail_Provo Portfolio.png',
    description: '',
      productLink: '/provo-portfolio',
      isClosed: false,
  },
  
  {
    id: '1',
    name: 'College Way & Stadium Way',
    title: '',
    imageUrl:
      './cwTH.png',
    description: '',
      productLink: '/college-way-stadium-way',
      isClosed: false,
  },
  {
    id: '1',
    name: 'At Home Rexburg',
    title: '',
    imageUrl:
      './At Home Rexburg Thumbnail.png',
    description: '',
      productLink: '/at-home-rexburg',
      isClosed: false,
  },
  {
    id: '1',
    name: 'Mt. Eyrie',
    title: '',
    imageUrl:
      './_Mt. Eyrie_Thumbnail.png',
    description: '',
      productLink: '/mt-eyrie',
      isClosed: false,
  },
  {
    id: '1',
    name: 'Lofts on 34th',
    title: '',
    imageUrl:
      './Lofts on 34th_Thumbnail.jpg',
    description: '',
      productLink: '/lofts-on-34th',
      isClosed: false,
  },
  {
    id: '1',
    name: 'Desert Sage',
    title: '',
    imageUrl:
      './DesertSage_Thumbnail.png',
    description: '',
      productLink: '/desert-sage-townhomes',
      isClosed: false,
  },


///////////// Closed ///////////

{
  id: '1',
  name: 'Twin Pines',
  title: '',
  imageUrl:
    './TP-Thumbnail.png',
  description: '',
    productLink: '/closed-twin-pines',
    isClosed: true,
},
  {
    id: '1',
    name: 'The Grand Portfolio',
    title: '',
    imageUrl:
      './Grand Portfolio_Thumbnail.png',
    description: '',
      productLink: '/closed-the-grand-portfolio',
      isClosed: true,
  },
  
  {
    id: '3',
    name: 'Revive Apartments',
    title: 'S',
    imageUrl:
      './_Revive Apartments_Aerial Square.png',
    description:
      '',
      productLink: '/closed-revive',
      isClosed: true,
  },
     {
    id: '1',
    name: 'Shulsen Lane',
    title: '',
    imageUrl:
      './shulsen-lane-thumbnail.jpg',
    description: '',
      productLink: '/closed-shulsen-lane',
      isClosed: true,
  },
    {
      id: '5',
      name: 'Murray Park View',
      title: 'S',
      imageUrl:
        './_Murray Park View_Exterior Square.png',
      description:
        '',
        productLink: '/closed-murray-park-view',
        isClosed: true,
    },
    {
      id: '6',
      name: 'MODA Spring Run',
      title: 'S',
      imageUrl:
        './_MODA Spring Run_Aerial Square.png',
      description:
        '',
        productLink: '/closed-moda-spring-run',
        isClosed: true,
    },
    {
      id: '7',
      name: 'Villas at Fern Circle',
      title: 'S',
      imageUrl:
        './_Fern_Circle_Square.png',
      description:
        '',
        productLink: '/closed-villas-at-fern-circle',
        isClosed: true,
    },
  ]