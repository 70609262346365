export const images = [
    {
      id: '01',
      src: '/Roers_Whitney_Lobby_F_01.jpg',
      alt: 'Whitney',
    },
    {
      id: '02',
      src: '/Roers_Whitney_SkyDeck_F.jpg',
      alt: 'Whitney',
    },
    {
      id: '03',
      src: '/Roers_Whitney_Unit_Living_F_01.jpg',
      alt: 'Whitney',
    },
    {
      id: '04',
      src: '/Whitney_West_Courtyard.jpg',
      alt: 'Whitney',
    },
    {
      id: '05',
      src: '/The Whitney_Aerial 2.jpg',
      alt: 'Whitney',
    },
  ]