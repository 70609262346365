
export const Pproducts = [
    {
      id: '1',
      name: '820 E Center Street',
      title: '',
      imageUrl:
        './Thumbnail_800 E Center Street.png',
      description: '',
        productLink: 'https://www.crexi.com/properties/1735610/utah-800-east-center-street',
        isClosed: false,
    },
    {
        id: '1',
        name: '444 W 200 N & 242 N 500 W',
        title: '',
        imageUrl:
          './Thumbnail_444 W 200 N & 242 N 500 W.png',
        description: '',
          productLink: 'https://www.crexi.com/properties/1735644/utah-444-west-200-north-242-north-500-west',
          isClosed: false,
      },
 
    ]