import {
    AspectRatio,
    Box,
    Skeleton,
    Text
  } from '@chakra-ui/react'
  import { Link } from 'react-router-dom';

  export const ProductCard = (props) => {


    const { product } = props
    const { name, imageUrl, productLink, isClosed } = product
    return (
      <Link to={productLink} target="_blank">
<Box position="relative">
          <AspectRatio ratio={4 / 4}>
            <Box
            backgroundSize={"cover"}
            bgImage={imageUrl}
              alt={name}
              draggable="false"
              fallback={<Skeleton />}
              borderRadius={{
                base: '0',
                md: '0',
              }}
              // style={{
              //   opacity: isClosed ? .4 : 1, // Set opacity to 0.7 when isClosed is true, otherwise 1
              // }}
            >
              {isClosed && (
          <Box 
          height={'100%'}
          width={'100%'}
          bg="gray.600"
          opacity={'.7'}
          
          ></Box>
          )}
        <Text
        fontSize={['8px','16px','18px']}
          paddingTop={'3px'}
          paddingLeft={'10px'}
          style={{ 
            position: 'absolute', 
            bottom: '0', 
            left: '0', 
            right: '0',
            // opacity: isClosed ? 1 : 0.7, // Set opacity to 0.7 when isClosed is not true, otherwise 1
            // backgroundColor: isClosed ? 'transparent' : 'gray.600', // Set bg color to 'gray.600' when isClosed is not true, otherwise 'transparent'
          }}
          bg={isClosed ? 'transparent' : 'gray.600'}
          opacity={isClosed ? '1' : '.8'}
          color="white"
          height={['13%','10%','36px']}
          textAlign="start"
        >{name}{isClosed ? ' - Closed' : ' - Available'}
        </Text>
      
          </Box>
          </AspectRatio>
        </Box>
      </Link>
        
    )
  }